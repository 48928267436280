import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'center',
    },
    '& .MuiDialog-paper': {
      width: (props) => props.width || 450,
      background: '#FFFFFF',
      borderRadius: 8,
    },
  },
  title: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
    textAlign: 'center',
    padding: 8,
    '& h2': { fontSize: '1.15rem' },
  },
  content: {
    padding: theme.spacing(3),

    '& > p': {
      marginBottom: 6,
    },
  },
  action: {
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  table: {
    border: '1px solid #E8EAEC',
  },
}));

export default function AlertModal({ open, title, onClose, onSubmit, submitLabel = 'Xác nhận', message }) {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions className={classes.action}>
        <Button type="button" variant="contained" color="secondary" onClick={onClose}>
          Đóng
        </Button>
        {onSubmit && (
          <Button type="submit" variant="contained" color="primary" onClick={onSubmit}>
            {submitLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
