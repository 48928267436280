import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Grid,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'center',
    },
    '& .MuiDialog-paper': {
      width: (props) => props.width || 450,
      background: '#FFFFFF',
      borderRadius: 10,
    },
  },
  title: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
  },
  BrokenContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  BrokenLabel: {
    fontWeight: 'bold',
    // marginTop: 15,
  },
  content: {
    padding: theme.spacing(3),

    '& > p': {
      marginBottom: 6,
    },
  },
  action: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  table: {
    border: '1px solid #E8EAEC',
  },
}));
const StyledTableCell = withStyles((theme) => ({
  root: {
    '&:not(:first-child)': {
      padding: '10px 5px',
    },
    '&:first-child': {
      padding: '10px 5px 10px 20px',
    },
  },
}))(TableCell);

const BrokenRow = React.memo(({ broken, index, brokens, handleChange, handleDelete }) => {
  return (
    <TableRow key={index}>
      <StyledTableCell style={{ width: '55%' }}>
        <TextField
          fullWidth
          size="small"
          select
          variant="outlined"
          name="Broken_Type_Code"
          value={broken.Broken_Type_Code || ''}
          onChange={(e) => handleChange(e, index)}
        >
          {brokens.map((broken) => (
            <MenuItem key={broken.id} value={broken.id}>
              {broken.value}
            </MenuItem>
          ))}
        </TextField>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: '35%' }}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          type="number"
          name="Quantity_In_Piece"
          value={broken.Quantity_In_Piece}
          onChange={(e) => handleChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: '10%' }}>
        <IconButton aria-label="delete" size="small" onClick={() => handleDelete(index)}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
});

const BrokenModal = React.memo((props) => {
  const classes = useStyles();
  const { isOpen, isDisabled, handleClose, handleSubmit, handleOpenSnackbar, list } = props;
  const { brokens } = useSelector((state) => state.metadata);
  const [brokenList, setBrokenList] = useState([]);

  const totalBroken = useMemo(() => {
    return brokenList.reduce((a, b) => a + Number(b.Quantity_In_Piece), 0);
  }, [brokenList]);

  const handleChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      const list = [...brokenList];
      if (name === 'Broken_Type_Code') {
        const check = brokenList.some((item, i) => item.Broken_Type_Code === value && i !== index);
        if (check) {
          handleOpenSnackbar('error', 'Loại hỏng đã tồn tại');
          brokenList[index].Broken_Type_Code = '';
          brokenList[index].Quantity_In_Piece = 0;
          return;
        }
        const broken = brokens?.find((item) => item.id === value);
        list[index].Broken_Type_Name = broken?.value;
      }
      list[index][name] = value;
      setBrokenList(list);
    },
    [brokenList, brokens, handleOpenSnackbar]
  );

  const handleSubmited = useCallback(() => {
    handleSubmit(
      brokenList.filter((item) => item.Broken_Type_Code !== '' && item.Quantity_In_Piece !== 0),
      totalBroken
    );
  }, [brokenList, totalBroken, handleSubmit]);

  const handleAddBroken = useCallback(() => {
    setBrokenList([
      ...brokenList,
      {
        Broken_Type_Code: '',
        Broken_Type_Name: '',
        Quantity_In_Piece: '',
      },
    ]);
  }, [brokenList]);

  const handleDelete = useCallback((index) => {
    setBrokenList((prevBrokenList) => prevBrokenList.filter((_, i) => i !== index));
  }, []);

  const handleCloseModal = () => {
    handleClose();
  };

  useEffect(() => {
    if (list) {
      setBrokenList(list);
    }
  }, [list]);

  return (
    <div>
      <Dialog open={isOpen} onClose={handleCloseModal} className={classes.root}>
        <DialogTitle id="modal-modal-title" className={classes.title}>
          Chi tiết hỏng
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.BrokenContainer}>
            <div className={classes.BrokenLabel}>Tổng số hỏng: {totalBroken}</div>
            {!isDisabled && (
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Thêm loại hỏng">
                  <IconButton onClick={handleAddBroken} size="small">
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </div>
          <TableContainer className={classes.table} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Loại hỏng</StyledTableCell>
                  <StyledTableCell align="left">Số lượng</StyledTableCell>
                  <StyledTableCell align="left">Xóa</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {brokenList?.map((broken, index) => (
                  <BrokenRow
                    key={index}
                    broken={broken}
                    index={index}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    brokens={brokens}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button type="button" variant="contained" color="secondary" onClick={handleCloseModal}>
            Đóng
          </Button>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmited}>
            Lưu
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

BrokenModal.propTypes = {
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOpenSnackbar: PropTypes.func,
  list: PropTypes.array,
};

export default BrokenModal;
